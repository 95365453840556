import React from "react"
import { Link } from "gatsby"
import PostMeta from "./PostMeta"
import { truncateText } from "../utils/text"

const PostPreview = ({ post, isLast }) => {
  const ExcerptWithoutLink = truncateText(post?.node?.excerpt, 400)
  return (
    <>
      <article
        className={`post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized archive-post`}
        id={`post-${post.databaseId}`}
      >
        <header className="entry-header has-text-align-center">
          <div className="entry-header-inner section-inner medium">
            {post.featuredImage !== null &&
              <img src={post?.node?.featuredImage?.node?.mediaItemUrl} className="featured" />
            }
            <h2 className="entry-title heading-size-2">
              <Link
                to={post?.node?.slug}
                dangerouslySetInnerHTML={{ __html: post?.node?.title }}
              />
            </h2>
          </div>
        </header>

        <div className="post-inner thin ">
          <div className="entry-content">
            <div dangerouslySetInnerHTML={{ __html: ExcerptWithoutLink }} />
          </div>
        </div>
      </article>

      {!isLast && (
        <hr width="40%"
        key={post?.node?.postId + "-hr"}
        aria-hidden="true"
      />
      )}
    </>
  )
}

export default PostPreview
